import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { Button } from "react-md";

function Hero() {
  const data = useStaticQuery(graphql`
    query {
      mainHero: file(relativePath: { eq: "images/ForHomeMainHero.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      forHomeBanner: file(
        relativePath: { eq: "images/DeltaMathForHomeCompAsset-cropped-2.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      forHomeAplus: file(
        relativePath: { eq: "images/for-home-banner-a-plus.png" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const imageData = data.mainHero.childImageSharp.fluid;
  const forHomeImageData = data.forHomeBanner.childImageSharp.fluid;
  const forHomeAplusImageData = data.forHomeAplus.childImageSharp.fluid;

  return (
    <div className="introducing-home-experiment-block">
      <BackgroundImage
        Tag="section"
        className="index__hero deltamath__forhome__hero"
        fluid={imageData}
        backgroundColor={`#303d4e`}
      >
        <div className="container index__hero-shadow">
          <div>
            <h1 className="index__hero-header index__hero-header-nowrap">
              Math done{" "}
              <span className="index__hero-underline-parent">
                right<span className="index__hero-underline-child"></span>
              </span>
              .
            </h1>
            <h1 className="index__hero-header">Depth, clarity, and rigor.</h1>
            <div className="index__hero-sub-header-container">
              <h2 className="index__hero-sub-header">
                <span>Built by teachers.</span> <span>Used by millions.</span>
              </h2>
            </div>
          </div>
          <div className="index__hero-buttons">
            <Link to="/teachers-schools">
              <Button
                className="index__hero-button"
                theme="secondary"
                themeType="contained"
              >
                For Teachers&#x200a;/ Schools
              </Button>
            </Link>
            <Link to="/students">
              <Button
                className="index__hero-button"
                theme="secondary"
                themeType="contained"
              >
                For Students
              </Button>
            </Link>
          </div>
        </div>
      </BackgroundImage>

      <BackgroundImage
        Tag="section"
        className="index__hero deltamath__forhome-banner__hero"
        fluid={forHomeImageData}
        backgroundColor={`#D9E4F6`}
        style={{
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "top",
          backgroundPositionX: "left",
          backgroundSize: "auto",
        }}
      >
        <img
          src={forHomeAplusImageData.src}
          alt="DeltaMath For Home A Plus"
          className="for-home-banner-a-plus"
        />

        <div className="container index__hero-shadow">
          <div>
            <h1 className="index__hero-header">DeltaMath for Home</h1>
            <div className="index__hero-sub-header-container">
              <h2 className="index__hero-sub-header">
                <span>
                  {" "}
                  Your personalized learning platform designed for{" "}
                  <strong>
                    at
                    <br />
                    -home success.
                  </strong>{" "}
                  Try it today with a 7-day free trial.
                </span>
              </h2>
            </div>
          </div>
          <div className="index__hero-buttons">
            <Link to="/home">
              <Button
                className="index__hero-button"
                theme="secondary"
                themeType="contained"
              >
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
}

export default Hero;
