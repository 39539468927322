import React, { useState, useEffect } from 'react';
import { Link } from "gatsby";
import '../../styles/components/Toast/TermsToast.scss';

const TermsToast = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const endDate = new Date('2025-01-15');
        const today = new Date();
        if (today < endDate) {
            const isDismissed = document.cookie.includes('dismissedTermsToast=true');
            setIsVisible(!isDismissed);
            return;
        }
    }, []);

    const handleClose = () => {
        // Set cookie to expire in 365 days
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 365);
        document.cookie = `dismissedTermsToast=true; expires=${expiryDate.toUTCString()}; path=/`;
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="terms-toast">
            <div className="terms-toast-content">
                <span>DeltaMath has updated its Terms of Service and Privacy Policies. <br />Follow <Link to="/terms-policies">this link</Link> or click Terms &amp; Policies below for more information.</span>
            </div>
            <button className="terms-toast-close" onClick={handleClose}>
                ×
            </button>
        </div>
    );
};

export default TermsToast; 